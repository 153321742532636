$color_light: #6cdccd;
$color_primary: #3f51b5;
$background_light: #f6f6f6;

@mixin createOverlayBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    content: '';

}   

@mixin cardstyle {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 9px #00000017;
}