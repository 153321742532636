/* You can add global styles to this file, and also import other style files */

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
    text-decoration: none;
}

body,html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.center {
    max-width: 1200px;
    margin: 0 auto;
    display: block;
    width: 100%;
}

@import '~@angular/material/theming';
@import './variables';

@include mat-core();

.mat-card-header-text {
    margin: 0 !important;
}


.table {
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #dedede;
    tr:first-child {
        th:first-child {
            background-color: transparent !important;
        }
    }
    .mat-header-row {
        th {
            font-size: 15px;
        }       
    }
    td {
        padding: 15px !important;
        font-size: 16px;
    }
}

.mat-column-espec {
    background-color: #5965ab;
    color: #fff;
    font-size: 20px !important;
    td {
        font-size: 20px !important;
    }
}

.list-plans {
    display: flex;
    padding: 40px 20px;
    justify-content: space-between;
    list-style: none;
    flex-wrap: wrap;
    > li {
        width: 32%;
        list-style: none;
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    a {
        display: block;
    }
}

.bt-flex {
    .mat-button-wrapper {
        display: flex;
        justify-content: center;
    }
    
}

.card-plan {
    .price {
        font-size: 3em;
        color: $color_primary;
        span, small {
            font-size: 0.5em;
        }
    }
    button {
        width: 100%;
    }
    .list {
        padding: 0;
        width: 100%;
        list-style: none;
        min-height: 300px;
        li {
            padding: 5px 0;
            font-size: 13px;
            display: flex;
            align-items: center;
            .material-icons {
                margin-right: 5px;
                font-size: 20px;
            }
            &.ok {
                .material-icons {
                    color: rgb(49, 167, 86);
                }
            }
            &.error {
                .material-icons {
                    color: rgb(167, 49, 49);
                }
            }
        }
    }
}

.mat-horizontal-stepper-header-container {
    @media (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.container {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
}

.banner-hero {
    height: 550px;
    background-color: #f6f6f6;
    width: 100%;
    position: relative;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left 15%;
    display: flex;
    color: #ffffff;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    @media (max-width: 600px) {
        height: 300px;
        font-size: 8px;
        .title {
            margin-bottom: 5px;
        }
    }
    .title {
        font-size: 4em;
        color: #ffffff;
        position: relative;
        z-index: 1;
        text-shadow: 3px 3px 2px #0000001a;
    }
    p {
        z-index: 1;
        font-size: 14px;
        font-weight: 300;
        margin-top: 15px;
    }
    a {
        z-index: 1;
        position: relative;
        min-width: 200px;
    }
}

.title-main {
    font-size: 3rem !important;
    line-height: 1.2 !important;
    font-weight: 400;
    @media (max-width: 700px) {
        font-size: 1.4rem !important;
    }
    strong {
        color: $color_light;
    }
}


.flag-text {
    h1 {
        strong {
            color: $color_light;
        }
    }
}

.main-footer {
    a {
        color: rgb(0, 166, 255);
    }
}